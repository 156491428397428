<template>
  <div>
    <div class="backgroundimg height" :style="cover_image"></div>
    <div class="heading">
      <div class="link link--move title" data-letters="eMenu">eMenu</div>
    </div>
  </div>
</template>
<script>
//import { mapState, mapGetters, mapActions } from 'vuex'
import axios from "@/axios";
export default {
  data() {
    return {
      loading: false,
      props: ["closed"],
      cover_image: {},
    };
  },
  created() {
    this.getOutlet();
  },
  methods: {
    getOutlet() {
      if (this.$route.params.outlet) {
        axios
          .post("/outlet/getByCode", {
            outlet_code: this.$route.params.outlet,
          })
          .then((res) => {
            console.log("outlet response", res.data.data);
            let outlet = res.data.data;
            if (outlet.cover_image !== "") {
              this.cover_image = {
                backgroundImage: "url(" + outlet.cover_image + ")",
              };
            } else {
              this.cover_image = {
                backgroundImage:
                  "url(" + require("@/assets/images/cover/default.webp") + ")",
              };
            }
          });
      } else {
        this.cover_image = {
          backgroundImage: "url(" + require("@/assets/images/hero.webp") + ")",
        };
      }
    },
  },
};
</script>
<style scoped>
.backgroundimg {
  /* background: url("../assets/images/cover/default.webp"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-attachment: fixed;
}
.height {
  height: 30vh;
  width: 100%;
}

.heading {
  margin-top: -119px;
  margin-bottom: 70px;
  padding-bottom: 13px;
  font-size: 25px;
  /* color: snow; */
  text-align: center;
}
.notice {
  font-size: 13px !important;
}
.link {
  outline: none;
  text-decoration: none;
  position: relative;
  font-size: 25px;
  line-height: 1;
  color: snow;
  display: inline-block;
}
.link--move {
  /* text-transform: uppercase; */
  font-weight: 900;
  overflow: hidden;
  line-height: 0.75;
  color: snow;
}
.title {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  width: 350px;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid #111;
  transition: all 1s ease-in-out;
}
@media screen and (max-width: 640px) {
  .title {
    width: 95%;
    font-size: 8vw;
  }
}
.link--move:hover {
  color: #fb6540;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5), 0 2px 0 rgba(0, 0, 0, 0.9);
}
.link--move::after {
  content: "";
  position: absolute;
  height: 16px;
  width: 100%;
  top: 50%;
  margin-top: -8px;
  right: 0;
  background: rgba(235, 109, 37, 0.5);
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.link--move:hover::after {
  transform: translate3d(100%, 0, 0);
}
.link--move::before {
  content: attr(data-letters);
  position: absolute;
  z-index: 2;
  overflow: hidden;
  color: #424242;
  white-space: nowrap;
  width: 0%;
  transition: width 0.4s 0.3s;
}
.link--kukuri:hover::before {
  width: 100%;
}
</style>
